<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import IconButton, { Icon } from "@smui/icon-button";
  import Radio from "@smui/radio";
  import Select, { Option } from "@smui/select";
  import Accordion, { Content, Header, Panel } from "@smui-extra/accordion";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { getContext } from "svelte";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import {
    ABSENCE,
    CHOICES_OF_TIME,
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    RedeliveryDateAdjustMethod,
    RedeliveryDateSpecifyMethod,
  } from "~/libs/constants";
  import { deliveryTarget } from "~/libs/stores";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {number} 配達不可の場合の理由区分 */
  export let extraEventType;

  /** @type {boolean} 再送の場合にtrue */
  export let resendFlag = false;

  /** @type {ConfirmDialog} 確認ダイアログコンポーネントのインスタンス */
  let dialog;

  /** @type {number} 再配達日時の調整方法の選択値 */
  let adjustMethod = !resendFlag
    ? RedeliveryDateAdjustMethod.WEB
    : RedeliveryDateAdjustMethod.TEL;

  /** @type {boolean} 再配達日時の調整要否の選択値 */
  let adjustNecessity = true;

  /** @type {number} 再配達日時の指定方法の選択値 */
  let specifyMethod =
    RedeliveryDateSpecifyMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK;

  /** @type {number} 時間帯プリセットの選択値 */
  let selectedTimeFramePreset = 0;

  /** @type {boolean} 再配達が不可能な日時を指定するかどうか */
  let specityRedeliveryImpossible = false;

  /** @type {Date} 電話で調整した再配達の日付 */
  let adjustedDate;

  /** @type {string} 電話で調整した再配達の時間帯の開始時間 */
  let startOfAdjustedTime = "";

  /** @type {string} 電話で調整した再配達の時間帯の終了時間 */
  let endOfAdjustedTime = "";

  /** @type {boolean} 自身で再配達を行うかどうかのチェック */
  let notSelfRedeliveryChecked = false;

  /** @type {boolean} 入力内容確認のチェック */
  let confirmChecked = false;

  /** @type {Array<Date>} 一週間の日付リスト */
  let dateList = [];
  for (let i = 0; i < 7; i++) {
    let date = new Date();
    date.setDate(date.getDate() + i);
    dateList[i] = date;
  }

  /** @type {Array<string>} 再配達が不可能な日時の時間帯の選択肢 */
  $: redeliveryPossibleTimeFrameList =
    appContext.timeFramePresetList[selectedTimeFramePreset].timeFrameList;

  /** @type {boolean} 再配達不可能な日時の指定アコーディオンを全て開くかどうか */
  let accordionAllOpenFlag = false;

  let accordionOpenFlag = [false, false, false, false, false, false, false];

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 配達対象の荷物情報 */
  let deliveryPackage = $deliveryTarget;

  /** @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>} 宅配ドライバーが再配達を行えない日時リスト */
  let redeliveryUnavailabilityDateAndTimeList = [];

  /**  @type {boolean} 対象の荷物の依頼主がメール通知送信可能の場合にtrue */
  let isAvailableEmail = false;
  /** @type {string} */
  let shipperName;
  for (let i = 0; i < userContext.deliveryList.length; i++) {
    if (
      userContext.deliveryList[i].trackingNumber ===
      deliveryPackage.trackingNumber
    ) {
      isAvailableEmail =
        userContext.deliveryList[i].customer.emailNotificationRequired;
      shipperName = userContext.deliveryList[i].shipperName;

      break;
    }
  }

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    dialog.openDialog();
  }
</script>

<div class="absentNotificationDialog wideWidthMdcDialog">
  <ConfirmDialog
    bind:this={dialog}
    type={ConfirmDialogTypes.CLOSE}
    mandatory={true}
  >
    <svelte:fragment slot="title">
      {#if !isAvailableEmail}
        「不在・置き配不可」登録
      {:else if !resendFlag}
        不在通知に含める内容
      {:else}
        変更通知に含める内容
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="content">
      <div class="pageWrapper">
        <div id="page1" class="page show">
          {#if !isAvailableEmail}
            <p style="color: #f00; margin-top: 10px;">
              本荷物は荷受人のメールアドレス登録がないため、不在通知票の投函が必要です。
            </p>
          {/if}
          <section>
            <h3>1. 再配達日時の調整</h3>
            <div class="sectionContents">
              {#if !resendFlag}
                <div class="selfRedelivery">
                  <FormField>
                    <Checkbox
                      bind:checked={notSelfRedeliveryChecked}
                      on:change={() => {
                        if (notSelfRedeliveryChecked) {
                          if (isAvailableEmail) {
                            adjustMethod = RedeliveryDateAdjustMethod.WEB;
                            specifyMethod =
                              RedeliveryDateSpecifyMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK;
                          } else {
                            adjustNecessity = false;
                          }
                        }
                      }}
                    />
                    <span slot="label">自分で再配達を行わない</span>
                  </FormField>
                </div>
              {/if}
              {#if isAvailableEmail}
                <div class="segmentButtonArea">
                  <label class="segmentButtonWrapper">
                    <input
                      type="radio"
                      name="adjustMethod"
                      value={RedeliveryDateAdjustMethod.WEB}
                      bind:group={adjustMethod}
                      on:click={() => {
                        confirmChecked = false;
                      }}
                      disabled={resendFlag ? "disabled" : ""}
                    />
                    <div class="segmentButton">Web</div>
                  </label>
                  <label class="segmentButtonWrapper">
                    <input
                      type="radio"
                      name="adjustMethod"
                      value={RedeliveryDateAdjustMethod.TEL}
                      bind:group={adjustMethod}
                      on:click={() => {
                        confirmChecked = false;
                      }}
                      disabled={notSelfRedeliveryChecked ? "disabled" : ""}
                    />
                    <div class="segmentButton">電話</div>
                  </label>
                </div>
                <p class="description">
                  {#if adjustMethod === RedeliveryDateAdjustMethod.WEB}
                    荷受人に不在通知メールを送信し、Web上で再配達の希望日時の設定を依頼します。
                  {:else if !resendFlag}
                    荷受人に電話をかけて調整した再配達の希望日時を登録し、その内容を含めた不在通知メールを送信します。
                  {:else}
                    荷受人から連絡があった再配達の希望日時を登録し、その内容を含めた変更通知メールを送信します。
                  {/if}
                </p>
              {:else}
                <div class="segmentButtonArea">
                  <label class="segmentButtonWrapper">
                    <input
                      type="radio"
                      name="adjustNecessity"
                      value={true}
                      bind:group={adjustNecessity}
                      on:click={() => {
                        confirmChecked = false;
                      }}
                      disabled={notSelfRedeliveryChecked ? "disabled" : ""}
                    />
                    <div class="segmentButton">電話をかける</div>
                  </label>
                  <label class="segmentButtonWrapper">
                    <input
                      type="radio"
                      name="adjustNecessity"
                      value={false}
                      bind:group={adjustNecessity}
                      on:click={() => {
                        confirmChecked = false;
                      }}
                      disabled={resendFlag ? "disabled" : ""}
                    />
                    <div class="segmentButton">連絡を待つ</div>
                  </label>
                </div>
                <p class="description">
                  {#if !adjustNecessity}
                    「不在・置き配不可」として登録し、荷受人からの再配達依頼の連絡を待ちます。
                  {:else if !resendFlag}
                    荷受人に電話をかけて再配達の日程調整を行い、調整内容を登録します。
                  {:else}
                    荷受人から連絡があった再配達の希望日時を登録します。
                  {/if}
                </p>
              {/if}
            </div>
          </section>
          {#if isAvailableEmail && adjustMethod === RedeliveryDateAdjustMethod.WEB}
            <!-- WEBでの調整 -->
            <section>
              <h3>2. 荷受人による再配達日時の指定方法</h3>
              <div class="sectionContents">
                <div class="deliveryMethodRadio">
                  <FormField>
                    <Radio
                      bind:group={specifyMethod}
                      value={RedeliveryDateSpecifyMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK}
                      on:click={() => {
                        confirmChecked = false;
                      }}
                    />
                    <span slot="label"
                      >何時以降なら受け取れるのかを1週間分指定</span
                    >
                  </FormField>
                  <FormField>
                    <Radio
                      bind:group={specifyMethod}
                      value={RedeliveryDateSpecifyMethod.DESIRED_DATE_AND_TIME}
                      on:click={() => {
                        confirmChecked = false;
                      }}
                      disabled={notSelfRedeliveryChecked ? "disabled" : ""}
                    />
                    <span slot="label">1つの日時を指定</span>
                  </FormField>
                </div>
              </div>
            </section>
            {#if specifyMethod === RedeliveryDateSpecifyMethod.DESIRED_DATE_AND_TIME}
              <!-- 希望日時を一つ指定 -->
              <div class="buttonArea">
                <Button
                  color="secondary"
                  variant="unelevated"
                  on:click={() => {
                    document.getElementById("page1").classList.remove("show");
                    document.getElementById("page2").classList.add("show");
                  }}
                >
                  <Label>次へ</Label>
                </Button>
              </div>
            {:else}
              <!-- 一週間分の受け取り可能時間帯指定 -->
              <div class="confirmArea">
                <p>
                  本内容で不在通知を送信します。内容に誤りがないか確認してください。
                </p>
                <FormField>
                  <Checkbox bind:checked={confirmChecked} />
                  <span slot="label">確認した</span>
                </FormField>
              </div>
              <div class="buttonArea">
                <Button
                  color="secondary"
                  variant="unelevated"
                  disabled={!confirmChecked}
                  on:click={() => {
                    deliveryPackage.redeliveryContext = {
                      redeliveryDatetimeSpecMethod: specifyMethod,
                      notificationResend: resendFlag,
                    };
                    deliveryTarget.set(deliveryPackage);

                    extraEventType = ABSENCE;
                  }}
                >
                  <Label>不在通知を送信する</Label>
                </Button>
              </div>
            {/if}
          {:else if (isAvailableEmail && adjustMethod === RedeliveryDateAdjustMethod.TEL) || !isAvailableEmail}
            {#if (isAvailableEmail && adjustMethod === RedeliveryDateAdjustMethod.TEL) || (!isAvailableEmail && adjustNecessity)}
              <!-- 電話での調整 -->
              <section>
                <h3>2. 電話で調整を行う</h3>
                <div class="sectionContents">
                  <button
                    class="callButton"
                    on:click={() => {
                      window.location.href = `tel:${deliveryPackage.receiverTel}`;
                    }}
                    ><span class="material-icons">call</span><span
                      >電話をかける</span
                    ></button
                  >
                  {#if !resendFlag}
                    <p class="description">
                      {#if isAvailableEmail}
                        電話が繋がらない場合はWebでの調整をお選びください。<br
                        />
                        また、Webでの調整依頼後に電話で調整が行えた場合は、改めて調整内容をご登録ください。
                      {:else}
                        電話が繋がらない場合は「連絡を待つ」をお選びください。<br
                        />
                        後ほど電話で調整が行えた場合は、改めて調整内容をご登録ください。
                      {/if}
                    </p>
                  {/if}
                </div>
              </section>
              <section>
                <h3>3. 電話で調整した再配達日時</h3>
                <div class="sectionContents">
                  <label class="selectLabel" for="deliveryDate">日付</label>
                  <div class="dateSelect">
                    <Select
                      id="deliveryDate"
                      variant="outlined"
                      bind:value={adjustedDate}
                    >
                      <Option value="" hidden>選択してください</Option>
                      {#each dateList as date}
                        <Option value={date}
                          >{formatDate(date, "M/d(E)", {
                            locale: localeJa,
                          })}</Option
                        >
                      {/each}
                    </Select>
                  </div>
                  <label class="selectLabel" for="deliveryTime">時間</label>
                  <div class="deliveryTimeSelect">
                    <Select
                      id="deliveryTime"
                      variant="outlined"
                      bind:value={startOfAdjustedTime}
                    >
                      <Option hidden value=""></Option>
                      {#each CHOICES_OF_TIME as time}
                        {#if endOfAdjustedTime !== "" ? time < endOfAdjustedTime : true}
                          <Option value={time}>{Number(time)}時</Option>
                        {/if}
                      {/each}
                    </Select>
                    <span>～</span>
                    <Select variant="outlined" bind:value={endOfAdjustedTime}>
                      <Option hidden value=""></Option>
                      {#each CHOICES_OF_TIME as time}
                        {#if startOfAdjustedTime !== "" ? time > startOfAdjustedTime : true}
                          <Option value={time}>{Number(time)}時</Option>
                        {/if}
                      {/each}
                    </Select>
                  </div>
                </div>
              </section>
            {/if}
            <div class="confirmArea">
              <p>
                {#if !isAvailableEmail && !resendFlag}
                  本内容で「不在・置き配不可」として登録します。内容に誤りがないか確認してください。
                {:else if !isAvailableEmail && resendFlag}
                  本内容で再配達日時を登録します。内容に誤りがないか確認してください。
                {:else if isAvailableEmail && !resendFlag}
                  本内容で不在通知を送信します。内容に誤りがないか確認してください。
                {:else if isAvailableEmail && resendFlag}
                  本内容で変更通知を送信します。内容に誤りがないか確認してください。
                {/if}
              </p>
              <FormField>
                <Checkbox
                  bind:checked={confirmChecked}
                  disabled={(isAvailableEmail ? true : adjustNecessity) &&
                  (!adjustedDate ||
                    startOfAdjustedTime === "" ||
                    endOfAdjustedTime === "")
                    ? "disabled"
                    : ""}
                />
                <span slot="label">確認した</span>
              </FormField>
            </div>
            {#if !isAvailableEmail}
              <div class="deliveryNoticeCaution">
                <p>
                  <em>不在連絡票</em>に必要事項を記載のうえ投函してください。
                </p>
                <table class="deliveryNoticeTable">
                  <tr>
                    <th>荷受人名</th>
                    <td>{deliveryPackage.receiverName}</td>
                  </tr>
                  <tr>
                    <th>ご依頼主名</th>
                    <td>{shipperName}</td>
                  </tr>
                  <tr>
                    <th>配達日時</th>
                    <td>{formatDate(new Date(), "M月d日 H時m分")}</td>
                  </tr>
                  <tr>
                    <th>送り状番号</th>
                    <td
                      >{formatTrackingNumber(
                        deliveryPackage.trackingNumber,
                      )}</td
                    >
                  </tr>
                  <tr>
                    <th>ご連絡事項</th>
                    <td>
                      {#if adjustNecessity}
                        <div>
                          ■再配達の調整結果
                          <div class="ml-5">
                            {#if adjustedDate}{formatDate(
                                adjustedDate,
                                "M/d(E)",
                                { locale: localeJa },
                              )}{/if}
                            {#if startOfAdjustedTime || endOfAdjustedTime}
                              {#if startOfAdjustedTime}{Number(
                                  startOfAdjustedTime,
                                )}時{/if}～{#if endOfAdjustedTime}{Number(
                                  endOfAdjustedTime,
                                )}時{/if}
                            {/if}
                            {#if !adjustedDate && !startOfAdjustedTime && !endOfAdjustedTime}&nbsp;{/if}
                          </div>
                        </div>
                      {/if}
                      {#if Number.isInteger(deliveryPackage.cashOnDeliveryAmount)}
                        <div>
                          ■代引き
                          <div class="ml-5">
                            あり（{deliveryPackage.cashOnDeliveryAmount.toLocaleString()}円）
                          </div>
                        </div>
                      {/if}
                    </td>
                  </tr>
                  {#if !adjustNecessity}
                    <tr>
                      <th>宅配ドライバー直通電話</th>
                      <td>荷受人から直接電話を受けたい場合は記載してください</td
                      >
                    </tr>
                  {/if}
                </table>
              </div>
            {/if}
            <div class="buttonArea">
              <Button
                color="secondary"
                variant="unelevated"
                disabled={!confirmChecked}
                on:click={() => {
                  if (isAvailableEmail || adjustNecessity) {
                    deliveryPackage.redeliveryContext = {
                      adjustedRedeliveryDatetime: {
                        date: formatDate(adjustedDate, "yyyy-MM-dd", {
                          locale: localeJa,
                        }),
                        timeFrame: startOfAdjustedTime + endOfAdjustedTime,
                      },
                      notificationResend: resendFlag,
                    };
                    deliveryTarget.set(deliveryPackage);
                  }

                  extraEventType = ABSENCE;
                }}
              >
                <Label>
                  {#if !isAvailableEmail}
                    登録する
                  {:else if !resendFlag}
                    不在通知を送信する
                  {:else}
                    変更通知を送信する
                  {/if}
                </Label>
              </Button>
            </div>
          {/if}
        </div>
        {#if isAvailableEmail && adjustMethod === RedeliveryDateAdjustMethod.WEB && specifyMethod === RedeliveryDateSpecifyMethod.DESIRED_DATE_AND_TIME}
          <!-- Webで調整、かつ希望日時を一つ指定 -->
          <div id="page2" class="page">
            <section>
              <h3>3. 再配達希望日時の選択肢</h3>
              <div class="sectionContents">
                <p class="description">
                  荷受人による再配達希望日時の指定で用いる時間帯の選択肢をお選びください。
                </p>
                <ul class="notes">
                  <li>メニュー画面から時間帯候補の編集が行えます。</li>
                </ul>
                {#each appContext.timeFramePresetList as preset, i}
                  <FormField>
                    <Radio bind:group={selectedTimeFramePreset} value={i} />
                    <span slot="label">{preset.name}</span>
                  </FormField>
                  <div class="presetDisplay">
                    {#each preset.timeFrameList as timeFrame}
                      <div class="item">
                        {Number(timeFrame.substring(0, 2))}時～{Number(
                          timeFrame.substring(2, 4),
                        )}時
                      </div>
                    {/each}
                  </div>
                {/each}
                <p class="description mt-20">
                  再配達ができない日時がある場合は指定してください。指定した日時は荷受人側で選択できなくなります。
                </p>
                <div class="segmentButtonArea">
                  <label class="segmentButtonWrapper">
                    <input
                      type="radio"
                      name="redeliveryPossible"
                      value={false}
                      bind:group={specityRedeliveryImpossible}
                    />
                    <div class="segmentButton">指定しない</div>
                  </label>
                  <label class="segmentButtonWrapper">
                    <input
                      type="radio"
                      name="redeliveryPossible"
                      value={true}
                      bind:group={specityRedeliveryImpossible}
                    />
                    <div class="segmentButton">指定する</div>
                  </label>
                </div>
                {#if specityRedeliveryImpossible}
                  <!-- 再配達不可日時を指定する -->
                  <div class="accordionArea">
                    <div class="buttonArea">
                      <Button
                        color="secondary"
                        variant="outlined"
                        on:click={() => {
                          accordionAllOpenFlag = !accordionAllOpenFlag;

                          for (let i = 0; i < accordionOpenFlag.length; i++) {
                            accordionOpenFlag[i] = accordionAllOpenFlag;
                          }
                        }}
                      >
                        <Label>
                          {#if accordionAllOpenFlag}
                            すべて閉じる
                          {:else}
                            すべて開く
                          {/if}
                        </Label>
                      </Button>
                    </div>
                    <Accordion multiple>
                      {#each dateList as date, i}
                        <Panel bind:open={accordionOpenFlag[i]}>
                          <Header>
                            {formatDate(date, "M/d(E)", { locale: localeJa })}
                            <IconButton
                              slot="icon"
                              toggle
                              pressed={accordionOpenFlag[i]}
                            >
                              <Icon class="material-icons" on>expand_less</Icon>
                              <Icon class="material-icons">expand_more</Icon>
                            </IconButton>
                          </Header>
                          <Content>
                            {#each redeliveryPossibleTimeFrameList as timeFrame}
                              <label class="checkboxWrapper">
                                <input
                                  type="checkbox"
                                  value={timeFrame}
                                  on:change={() => {
                                    var i = -1;
                                    redeliveryUnavailabilityDateAndTimeList.forEach(
                                      (element, index) => {
                                        if (
                                          element.date ===
                                            formatDate(date, "yyyy-MM-dd", {
                                              locale: localeJa,
                                            }) &&
                                          element.timeFrame === timeFrame
                                        ) {
                                          i = index;
                                        }
                                      },
                                    );
                                    if (i !== -1) {
                                      redeliveryUnavailabilityDateAndTimeList.splice(
                                        i,
                                        1,
                                      );
                                    } else {
                                      redeliveryUnavailabilityDateAndTimeList.push(
                                        {
                                          date: formatDate(date, "yyyy-MM-dd", {
                                            locale: localeJa,
                                          }),
                                          timeFrame: timeFrame,
                                        },
                                      );
                                    }
                                  }}
                                />
                                <div class="timeItem">
                                  {Number(
                                    timeFrame.substring(0, 2),
                                  )}時～{Number(timeFrame.substring(2, 4))}時
                                </div>
                              </label>
                            {/each}
                          </Content>
                        </Panel>
                      {/each}
                    </Accordion>
                  </div>
                {/if}
              </div>
            </section>
            <div class="confirmArea">
              <p>
                本内容で不在通知を送信します。内容に誤りがないか確認してください。
              </p>
              <FormField>
                <Checkbox bind:checked={confirmChecked} />
                <span slot="label">確認した</span>
              </FormField>
            </div>
            <div class="buttonArea">
              <Button
                color="secondary"
                variant="unelevated"
                disabled={!confirmChecked}
                on:click={() => {
                  deliveryPackage.redeliveryContext = {
                    redeliveryDatetimeSpecMethod: specifyMethod,
                    timeFramePreset: redeliveryPossibleTimeFrameList,
                    notificationResend: resendFlag,
                  };
                  if (notSelfRedeliveryChecked && specityRedeliveryImpossible) {
                    deliveryPackage.redeliveryContext[
                      "redeliveryUnavailability"
                    ] = redeliveryUnavailabilityDateAndTimeList;
                  }
                  deliveryTarget.set(deliveryPackage);

                  extraEventType = ABSENCE;
                }}
              >
                <Label>不在通知を送信する</Label>
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                on:click={() => {
                  document.getElementById("page1").classList.add("show");
                  document.getElementById("page2").classList.remove("show");
                }}
              >
                <Label>戻る</Label>
              </Button>
            </div>
          </div>
        {/if}
      </div>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .absentNotificationDialog {
    :global(.mdc-dialog__title) {
      text-align: center;
    }
  }
  .pageWrapper .page {
    display: none;
  }
  .pageWrapper .page.show {
    display: block;
  }
  section {
    margin-top: 20px;
  }
  h3 {
    color: #000;
    font-size: 16px;
    font-weight: normal;
  }
  .sectionContents {
    color: #000;
    margin: 5px 15px 10px;

    :global(.mdc-select .mdc-select__anchor) {
      width: 100%;
    }
    :global(
        .mdc-select .mdc-select__menu .mdc-deprecated-list-item[hidden="true"]
      ) {
      display: none;
    }
    :global(.mdc-select .mdc-select__menu.mdc-menu-surface) {
      max-height: 200px !important;
    }
  }
  .segmentButtonArea {
    display: flex;
    margin-top: 5px;
  }
  .segmentButtonWrapper {
    display: block;
    width: calc(100% / 2);
  }
  .segmentButtonWrapper input[type="radio"] {
    display: none;
  }
  .segmentButton {
    color: #018786;
    border: 1px solid #018786;
    padding: 6px 0;
    text-align: center;
    width: 100%;
  }
  .segmentButtonWrapper:first-of-type .segmentButton {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .segmentButtonWrapper:last-of-type .segmentButton {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  input[type="radio"]:checked + .segmentButton {
    background-color: #018786;
    color: #fff;
    font-weight: bold;
  }
  input[type="radio"]:disabled + .segmentButton {
    background-color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.38);
  }
  .description {
    margin-top: 5px;
  }
  .deliveryMethodRadio {
    :global(
        .mdc-radio
          [aria-disabled="true"]
          .mdc-radio__native-control:not(:checked)
          + .mdc-radio__background
          .mdc-radio__outer-circle
      ) {
      background-color: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.2);
    }
    :global(
        .mdc-radio
          .mdc-radio__native-control:disabled:not(:checked)
          + .mdc-radio__background
          .mdc-radio__outer-circle
      ) {
      background-color: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
  .presetDisplay {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-left: 20px;
  }
  .presetDisplay .item {
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    box-sizing: border-box;
    color: #696969;
    font-size: 12px;
    line-height: 2;
    margin: 2px 1px 0;
    padding: 2px 0;
    text-align: center;
    width: calc(33% - 2px);
  }
  .notes {
    font-size: 12px;
    margin-top: 5px;
  }
  .notes li {
    list-style: none;
    margin-left: 1.2em;
    text-indent: -1.2em;
  }
  .notes li::before {
    content: "※";
    margin-right: 3px;
  }
  .accordionArea {
    :global(
        .smui-accordion .smui-accordion__panel.smui-accordion__panel--open
      ) {
      margin-top: 0;
      margin-bottom: 0;
    }
    :global(.smui-accordion .smui-accordion__panel > .smui-accordion__header) {
      background-color: #e7e7e7;
      border-top: 1px solid #fff;
    }
    :global(.smui-accordion .smui-accordion__panel > .smui-paper__content) {
      padding: 0;
    }
    :global(
        .smui-accordion
          .smui-accordion__panel.smui-accordion__panel--open
          > .smui-paper__content
      ) {
      padding: 0;
    }
  }
  .accordionArea .buttonArea {
    margin-bottom: 5px;
    text-align: right;

    :global(.mdc-button) {
      font-size: 12px;
      padding: 0;
      text-align: center;
      width: 120px !important;
      height: auto;
    }
  }
  .accordionArea input[type="checkbox"] {
    display: none;
  }
  .accordionArea .timeItem {
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    border-top: 1px solid #d4d4d4;
    padding: 10px 0;
    text-align: center;
  }
  .accordionArea input[type="checkbox"]:checked + .timeItem {
    background-color: #eae2f0;
  }
  .callButton {
    padding: 5px 0;
    background-color: #fff;
    border: 1px solid #018786;
    border-radius: 4px;
    width: 100%;

    span {
      font-size: 15px;
      color: #018786;

      &.material-icons {
        font-size: 20px;
        margin-right: 3px;
        vertical-align: middle;
      }
    }
  }
  .selectLabel {
    display: block;
    font-weight: bold;
    margin-top: 5px;
  }
  .dateSelect {
    :global(.mdc-select) {
      width: 100%;
    }
  }
  .deliveryTimeSelect {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    :global(.mdc-select .mdc-select__anchor) {
      width: 120px;
    }
  }
  .deliveryTimeSelect > span {
    display: inline-block;
    width: 2em;
  }
  .confirmArea {
    color: #000;
    margin-top: 30px;

    :global(
        .mdc-checkbox
          .mdc-checkbox__native-control[disabled]:not(:checked):not(
            :indeterminate
          ):not([data-indeterminate="true"])
          ~ .mdc-checkbox__background
      ) {
      background-color: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
  .deliveryNoticeCaution {
    background-color: #fae6e6;
    border-radius: 2px;
    color: #333;
    font-size: 0.875rem;
    line-height: 1.6;
    margin-top: 10px;
    padding: 8px 10px;
    text-align: left;
  }
  .deliveryNoticeCaution em {
    font-weight: bold;
  }
  .deliveryNoticeTable {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    margin-top: 10px;
    width: 100%;
  }
  .deliveryNoticeTable th {
    background-color: #f7c4c4;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    width: 110px;
    padding: 5px 8px;
  }
  .deliveryNoticeTable td {
    background-color: #ffebeb;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 5px 8px;
  }
  .deliveryNoticeTable .ml-5 {
    margin-left: 5px;
  }
  .buttonArea {
    margin-top: 20px;

    :global(.mdc-button) {
      width: 100%;
    }
    :global(.mdc-button:not(:first-of-type)) {
      margin-top: 20px;
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
</style>
