const addressUtils = {
  /**
   * 都道府県を除いた住所を返します。
   * @param {string} address 住所
   * @returns {string} 都道府県を除いた住所
   */
  trimPrefecture(address) {
    return address.replace(/^.{2,3}[都道府県]/, "");
  },

  /**
   * 2つの住所を半角スペース付きで結合して返す。
   * @param {string} address1
   * @param {string} address2
   * @returns {string}
   */
  joinWithSpace(address1, address2) {
    return address2 ? address1 + " " + address2 : address1;
  },
};

export default Object.freeze(addressUtils);
