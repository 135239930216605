<script>
  import Button, { Icon, Label } from "@smui/button";
  import IconButton from "@smui/icon-button";
  import List, { Graphic, Item, Meta, Separator, Text } from "@smui/list";
  import { getContext } from "svelte";
  import { fade } from "svelte/transition";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpIndex from "~/components/help/HelpIndex.svelte";
  import { CONTEXT_KEY_USER, ConfirmDialogTypes } from "~/libs/constants";
  import iosNativeApp from "~/libs/iosNativeApp";
  import pageRouter from "~/libs/pageRouter";
  import { toast } from "~/libs/toast";
  import ConfigurationDialog from "~/pages/OtherMenu/ConfigurationDialog.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {string} バージョン表示用のコミットハッシュ */
  const commitHash =
    (import.meta.env.VITE_COMMIT_HASH || "N/A").substring(0, 7) +
    iosNativeApp.getCurrentVersion().replace(/(.+)/, "-$1");

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;
  /** @type {import("svelte").ComponentType<HelpIndex>} */
  let helpContents;

  /**
   * 設定ダイアログコンポーネントのインスタンス
   * @type {ConfigurationDialog}
   */
  let configurationDialog;

  /**
   * ダイアログコンポーネント（ログアウトNG用）のインスタンス
   * @type {ConfirmDialog}
   */
  let cannotLogoutDialog;

  /**
   * ログアウト処理を行う。
   */
  async function logout() {
    const numOfPkgsGroupByStatus =
      userContext.getNumberOfPackagesGroupByStatus();
    if (
      (import.meta.env.MODE !== "production" &&
        import.meta.env.MODE !== "staging") ||
      (userContext.inTransitDeliveryList === undefined &&
        numOfPkgsGroupByStatus?.未 === 0 &&
        numOfPkgsGroupByStatus?.不 === 0)
    ) {
      pageRouter.moveToLogin();
    } else {
      cannotLogoutDialog.openDialog();
    }
  }
</script>

<div class="mainContentsWrapper">
  <!-- ヘッダー -->
  <Header>
    <svelte:fragment slot="center">その他メニュー</svelte:fragment>
  </Header>

  <main in:fade>
    <div class="loginUser">
      <span class="material-icons md-dark md-48">account_circle</span>
      <p>
        <span class="userName">{userContext.loginUser?.username ?? ""}</span>
        <span class="userRole"
          >ユーザ種別：{userContext.hasDriverRole()
            ? "宅配ドライバー"
            : userContext.hasContractDriverRole()
              ? "基幹配送担当者"
              : ""}</span
        >
      </p>
    </div>

    <div class="menuList">
      <List>
        <Item
          on:SMUI:action={() => {
            pageRouter.moveToNotification();
          }}
        >
          <Graphic class="material-icons">notifications</Graphic>
          <Text>お知らせ</Text>
          <Meta class="material-icons">arrow_forward_ios</Meta>
        </Item>

        {#if import.meta.env.VITE_ENABLED_PRESET_DELIVERY_TIMEFRAME_FUNCTION === "true" && userContext.hasDriverRole()}
          <Separator />
          <Item
            on:SMUI:action={() => {
              pageRouter.moveToPresetOfDeliveryTimeFrame();
            }}
          >
            <Graphic class="material-icons">schedule</Graphic>
            <Text>再配達の時間帯候補の設定</Text>
            <Meta class="material-icons">arrow_forward_ios</Meta>
          </Item>
        {/if}

        <Separator />
        <Item
          on:SMUI:action={() => {
            pageRouter.moveToPasswordChange();
          }}
        >
          <Graphic class="material-icons">key</Graphic>
          <Text>パスワードを変更</Text>
          <Meta class="material-icons">arrow_forward_ios</Meta>
        </Item>

        <Separator />
        <Item
          on:SMUI:action={() => {
            helpContents = HelpIndex;
            helpBase = HelpBase;
          }}
        >
          <Graphic class="material-icons">help_center</Graphic>
          <Text>ヘルプを表示</Text>
          <Meta class="material-icons">arrow_forward_ios</Meta>
        </Item>

        <Separator />
        <Item
          on:SMUI:action={() => {
            configurationDialog.openDialog();
          }}
        >
          <Graphic class="material-icons">settings</Graphic>
          <Text>アプリの設定</Text>
          <Meta class="material-icons">arrow_forward_ios</Meta>
        </Item>

        {#if window.webkit?.messageHandlers && import.meta.env.MODE !== "production"}
          <Separator />
          <Item
            on:SMUI:action={() => {
              pageRouter.moveToNativeAppLog();
            }}
          >
            <Graphic class="material-icons">phone_iphone</Graphic>
            <Text>ネイティブアプリログを表示</Text>
            <Meta class="material-icons">arrow_forward_ios</Meta>
          </Item>
        {/if}
      </List>
    </div>

    <div class="logout">
      <Button
        color="secondary"
        variant="outlined"
        on:click={() => {
          logout();
        }}
      >
        <Label>ログアウト</Label>
        <Icon class="material-icons">logout</Icon>
      </Button>
    </div>

    <div class="appVersion">
      <Button
        color="secondary"
        on:click={() => {
          navigator.clipboard.writeText(commitHash);
          toast.info("アプリのバージョンをクリップボードにコピーしました", {
            popsWhenPageMoved: true,
          });
        }}
      >
        <Label>Ver. {commitHash}</Label>
        <Icon class="material-icons">content_copy</Icon>
      </Button>
      <div
        style="width: 1px; height: 16px; margin: 0 3px; background-color: #ccc;"
      ></div>
      <IconButton
        class="material-icons md-dark"
        size="button"
        on:click={() => {
          window.location.reload();
        }}>refresh</IconButton
      >
    </div>
  </main>

  <Footer />
</div>

<div class="subContentsWrapper">
  <!-- ヘルプ表示 -->
  <svelte:component
    this={helpBase}
    {helpContents}
    clickConfirm={() => {
      helpBase = null;
      helpContents = null;
    }}
  />

  <ConfigurationDialog bind:this={configurationDialog} />

  <!-- ログアウト不可ダイアログ -->
  <ConfirmDialog
    bind:this={cannotLogoutDialog}
    type={ConfirmDialogTypes.OK_CLOSE}
  >
    <svelte:fragment slot="title">荷物が残っています</svelte:fragment>
    <svelte:fragment slot="content">
      {#if userContext.hasDriverRole()}
        <p>配達が完了していない荷物が残っているため、ログアウトできません。</p>
        {#if userContext.getNumberOfPackagesGroupByStatus().不 > 0}
          <p>
            持ち戻りを行う荷物は配送センターに戻して、保管手続（QRコードの読み取り）を行ってください。
          </p>
        {/if}
      {:else}
        <p>輸送中の荷物が残っているため、ログアウトできません。</p>
      {/if}
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  .loginUser {
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 13px 0 13px 9px;

    p {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: rgba(0, 0, 0, 0.54);

      .userName {
        font-size: 16px;
        font-weight: 500;
      }

      .userRole {
        font-size: 13px;
      }
    }
  }

  .menuList {
    :global(.mdc-deprecated-list-item) {
      background-color: #fff;
    }
  }

  .logout {
    align-self: center;
    margin: 20px 0;
  }

  .appVersion {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 2px;

    :global(.mdc-button__label) {
      text-transform: none;
    }
  }
</style>
