<script>
  import Button, { Label } from "@smui/button";
  import { getContext, tick } from "svelte";
  import { fade } from "svelte/transition";

  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import { CONTEXT_KEY_APP } from "~/libs/constants";
  import SetPresetDialog from "~/pages/OtherMenu/PresetOfDeliveryTimeFrame/SetPresetDialog.svelte";

  /** プリセット登録のダイアログ @type {SetPresetDialog} */
  let setPresetDialog;

  /** @type number  */
  let presetIndex;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  let presetList = appContext.timeFramePresetList;

  function updatePreset(event) {
    presetList = event.detail.presetList;
    // 画面更新のために代入
    presetList = presetList;
  }
</script>

<div class="mainContentsWrapper">
  <!-- ヘッダー -->
  <Header>
    <svelte:fragment slot="center">再配達の時間帯候補の設定</svelte:fragment>
  </Header>

  <main in:fade>
    <p class="lead">
      再配達の希望日時の設定で用いる時間帯候補の設定が行えます。
    </p>
    <div class="presetListArea">
      <ul class="presetList">
        {#each presetList as preset, i}
          <li>
            <div class="presetHead">
              <div class="presetTitle">{preset.name}</div>
              {#if i !== 0}
                <div class="presetBtnArea">
                  <Button
                    color="secondary"
                    variant="unelevated"
                    on:click={async () => {
                      presetIndex = i;
                      await tick();
                      setPresetDialog.openDialog();
                    }}
                  >
                    <Label>編集</Label>
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    style="background-color: #fff"
                    on:click={() => {
                      presetList.splice(i, 1);
                      presetList = presetList;
                      appContext.timeFramePresetList = presetList;
                      appContext.store();
                    }}
                  >
                    <Label>削除</Label>
                  </Button>
                </div>
              {/if}
            </div>
            <ol class="timeFrameList">
              {#each preset.timeFrameList as timeFrame}
                <li>
                  {Number(timeFrame.substring(0, 2))}時～{Number(
                    timeFrame.substring(2, 4),
                  )}時
                </li>
              {/each}
            </ol>
          </li>
        {/each}
      </ul>
    </div>
    <div class="btnArea">
      <Button
        color="secondary"
        variant="unelevated"
        on:click={async () => {
          presetIndex = presetList.length;
          await tick();
          setPresetDialog.openDialog();
        }}
      >
        <Label>追加する</Label>
      </Button>
    </div>

    <!-- ダイアログ -->
    <div class="dialogs">
      <SetPresetDialog
        bind:this={setPresetDialog}
        index={presetIndex}
        on:update={updatePreset}
      />
    </div>
  </main>

  <Footer />
</div>

<style lang="scss">
  main {
    line-height: 1.4;
  }
  .lead {
    margin: 30px 20px 0;
  }
  .presetListArea {
    margin: 30px 20px 0;
  }
  .presetList li {
    list-style: none;
  }
  .presetList .presetHead {
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
  }
  .presetList .presetHead .presetBtnArea {
    display: flex;

    :global(.mdc-button) {
      font-size: 12px;
      height: auto;
    }
    :global(.mdc-button:not(:first-of-type)) {
      margin-left: 5px;
    }
  }
  .timeFrameList {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 20px 30px;
  }
  .timeFrameList li {
    background-color: #fff;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    font-size: 14px;
    padding: 6px 0;
    text-align: center;
    width: calc(100% / 3);
  }
  .timeFrameList li:nth-of-type(n + 4) {
    border-top: none;
  }
  .timeFrameList li:nth-of-type(3n + 2),
  .timeFrameList li:nth-of-type(3n) {
    border-left: none;
  }
  .btnArea {
    margin: 50px 20px;

    :global(.mdc-button) {
      width: 100%;
    }
  }
  .dialogs {
    :global(.confirmDialog .mdc-dialog__content) {
      text-align: left;
      font-size: 15px;
    }
  }
</style>
