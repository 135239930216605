<script>
  import { escape } from "html-escaper";
  import { fade } from "svelte/transition";

  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import iosNativeApp from "~/libs/iosNativeApp";

  /**
   * @type {string}
   */
  let nativeAppOsLog = "";

  (async () => {
    nativeAppOsLog = await iosNativeApp.getOsLog();
  })();
</script>

<div class="mainContentsWrapper">
  <!-- ヘッダー -->
  <Header>
    <svelte:fragment slot="center">ネイティブアプリログ</svelte:fragment>
  </Header>

  <main in:fade>
    <div class="notificationList">
      <span>{@html escape(nativeAppOsLog).replace(/\n/g, "<br />")}</span>
    </div>
  </main>

  <Footer />
</div>

<style lang="scss">
  .notificationList {
    font-size: 14px;
    width: 100%;
  }
</style>
