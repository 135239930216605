<script>
</script>

<header>
  <div class="left"></div>
  <div class="center">
    <slot name="center" />
  </div>
  <div class="right">
    <slot name="right" />
  </div>
</header>

<style lang="scss">
  header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    min-height: 40px;
    border-bottom: solid 0.5px #b2b2b2;
    background-color: #fff;
  }

  .left {
    min-width: 72px;
  }

  .center {
    flex-grow: 1;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }

  .right {
    min-width: 72px;
    text-align: right;
  }
</style>
