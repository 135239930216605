import {
  AddressTypeForMap,
  STATUS_DELIVERED,
  STATUS_OUT_FOR_DELIVERY,
} from "~/libs/constants";
import depotLocations from "~/libs/depotLocations";
import logger from "~/libs/logger";
import {
  calcDistance,
  formatDistanceInMeters,
  parseAddress,
} from "~/libs/mapUtils";
import { routing } from "~/libs/route";

const deliveryListUtils = {
  /**
   * 配達リストから指定した送り状番号を持つ荷物を検索して返します。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList 配達リスト
   * @param {string} trackingNumber 送り状番号
   * @returns {import("~/libs/commonTypes").DeliveryPackage} 該当する荷物情報。合致するものがない場合はundefined
   */
  findByTrackingNumber(deliveryList, trackingNumber) {
    if (!Array.isArray(deliveryList) || !trackingNumber) {
      return undefined;
    }
    return deliveryList.find((e) => e.trackingNumber === trackingNumber);
  },

  /**
   * 配達リストを持ち出し日時の昇順でソートする。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList 配達リスト
   */
  sortWithAscOrder(deliveryList) {
    deliveryList.sort((lhs, rhs) => compareOutForDeliveryAt(lhs, rhs));
  },

  /**
   * 配達リストを持ち出し日時の降順でソートする。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList 配達リスト
   */
  sortWithDescOrder(deliveryList) {
    deliveryList.sort((lhs, rhs) => compareOutForDeliveryAt(rhs, lhs));
  },

  /**
   * 配達リストを総移動距離が短い順（巡回セールスマン問題）でソートする。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList 配達リスト
   * @param {GeolocationPosition} position 現在地
   */
  async sortWithTSP(deliveryList, position) {
    const sortTarget = [];
    for (const deliveryPackage of deliveryList) {
      if (deliveryPackage.statusText === "未" && deliveryPackage.latlon) {
        sortTarget.push(deliveryPackage);
      } else {
        deliveryPackage.order = Infinity;
      }
    }

    // 郵便番号、住所1で事前ソートを実施（TSPで使用する乱数の都合でソート前のリストの順序に影響を受ける）
    sortTarget.sort((lhs, rhs) => {
      let result = lhs.receiverPostcode?.localeCompare?.(rhs.receiverPostcode);
      return result !== 0
        ? result
        : lhs.receiverAddress1?.localeCompare?.(rhs.receiverAddress1);
    });
    console.log(
      "TSP事前ソート:",
      JSON.stringify(
        sortTarget.map((e) => [
          e.trackingNumber,
          e.receiverPostcode,
          e.receiverAddress1,
        ]),
      ),
    );

    /** ゴール地点（持ち出した配送センターの一つ）の緯度・経度 @type {{latitude: number, longitude: number}} */
    let goalLatLon;
    const depotLocationMap = await depotLocations.getCentersMap();
    for (const deliveryPackage of sortTarget) {
      const depot = depotLocationMap.get(
        deliveryPackage.outForDeliveryLocationId,
      );
      if (depot) {
        goalLatLon = {
          latitude: depot.latitude,
          longitude: depot.longitude,
        };
        break;
      }
    }

    await routing(
      {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
      sortTarget,
      goalLatLon,
    );
    deliveryList.sort((a, b) => a.order - b.order);
  },

  /**
   * DeliveryPackageとして必要なフィールドを初期化する。
   * @param {import("~/libs/commonTypes").DeliveryPackage} newPackage
   * @param {import("~/libs/commonTypes").DeliveryPackage} [oldPackage]
   * @returns {Promise<void>}
   */
  initDeliveryPackage(newPackage, oldPackage) {
    switch (newPackage.status) {
      case STATUS_OUT_FOR_DELIVERY:
        newPackage.statusText = "未";
        break;
      case STATUS_DELIVERED:
        newPackage.statusText = "済";
        newPackage.deliveredTimestamp = Date.now();
        logger.error(
          "[deliveryListUtils] 配完の荷物に対する初期化(意図しないパターン)",
          {
            trackingNumber: newPackage?.trackingNumber,
            oldStatus: oldPackage?.status,
          },
        );
        break;
      default:
        newPackage.statusText = "不";
        break;
    }

    if (oldPackage) {
      if (newPackage.correctedReceiverAddress) {
        newPackage.addressForMap = AddressTypeForMap.CORRECTED;
      } else {
        newPackage.addressForMap = oldPackage.addressForMap;
      }
      newPackage.enteredAddress = oldPackage.enteredAddress;
      newPackage.latlon = oldPackage.latlon;
      newPackage.personalMemo = oldPackage.personalMemo;
      newPackage.receivedPushType = oldPackage.receivedPushType;

      if (
        oldPackage.statusText === "不" &&
        newPackage.status === oldPackage.status &&
        newPackage.outForDeliveryAt === oldPackage.outForDeliveryAt
      ) {
        // 旧荷物の区分が「不」で、かつ配送ステータスと持ち出し日時が同一の場合は、新荷物も「不」とする
        newPackage.statusText = "不";
      }

      if (
        oldPackage.statusText === "未" &&
        Number.isInteger(newPackage.returnStatus)
      ) {
        // 旧荷物の区分が「未」で、新荷物の返品ステータスが設定されている場合は、新荷物を「不」とする
        newPackage.statusText = "不";
      }
    } else {
      if (newPackage.correctedReceiverAddress) {
        newPackage.addressForMap = AddressTypeForMap.CORRECTED;
      } else {
        newPackage.addressForMap = AddressTypeForMap.REGISTERED;
      }
      newPackage.enteredAddress = "";
      if (Number.isInteger(newPackage.returnStatus)) {
        // 返品ステータスが設定されている場合は、新荷物を「不」とする
        newPackage.statusText = "不";
      }
    }

    // お届け先住所の緯度・経度を計算して設定
    if (
      !oldPackage ||
      newPackage.receiverAddress1 !== oldPackage.receiverAddress1 ||
      newPackage.receiverAddress2 !== oldPackage.receiverAddress2 ||
      newPackage.correctedReceiverAddress !==
        oldPackage.correctedReceiverAddress
    ) {
      return deliveryListUtils.calculateAndSetLatLon(newPackage);
    }

    return Promise.resolve();
  },

  /**
   * リスト項目のお届け先住所（3種類）から緯度経度を計算して設定する。
   * @param {import("~/libs/commonTypes").DeliveryPackage} deliveryPackage
   */
  async calculateAndSetLatLon(deliveryPackage) {
    let address;
    if (
      deliveryPackage.addressForMap === AddressTypeForMap.INPUTTED &&
      deliveryPackage.enteredAddress
    ) {
      // 2: 宅配ドライバーが手入力した住所
      address = deliveryPackage.enteredAddress;
    } else if (
      deliveryPackage.addressForMap === AddressTypeForMap.CORRECTED &&
      deliveryPackage.correctedReceiverAddress
    ) {
      // 3: 荷受人による訂正住所
      address = deliveryPackage.correctedReceiverAddress;
    } else {
      // 1: 登録住所
      address = deliveryPackage.receiverAddress1;
      if (deliveryPackage.receiverAddress2) {
        address += deliveryPackage.receiverAddress2;
      }
    }

    try {
      deliveryPackage.latlon = await parseAddress(address);
    } catch (error) {
      console.warn(error);
      deliveryPackage.latlon = null;
    }
  },

  /**
   * 現在地をもとに、配達リストの各お届け先への距離を反映する。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList 配達リスト
   * @param {GeolocationPosition} currentPosition 現在地
   */
  setDistance(deliveryList, currentPosition) {
    for (const deliveryPackage of deliveryList) {
      deliveryListUtils.setPackageDistance(deliveryPackage, currentPosition);
    }
  },

  /**
   * 現在地をもとに、配達リストの荷物に各お届け先への距離を反映する。
   * @param {import("~/libs/commonTypes").DeliveryPackage} deliveryPackage 配達リストの荷物
   * @param {GeolocationPosition} currentPosition 現在地
   */
  setPackageDistance(deliveryPackage, currentPosition) {
    if (deliveryPackage.latlon && currentPosition) {
      deliveryPackage.distance = formatDistanceInMeters(
        Math.round(
          calcDistance(
            currentPosition.coords.latitude,
            currentPosition.coords.longitude,
            deliveryPackage.latlon.latitude,
            deliveryPackage.latlon.longitude,
          ),
        ),
      );
    } else {
      deliveryPackage.distance = null;
    }
  },

  /**
   * 配完後24時間以上が経過した荷物を削除した新しい配達リストを返す。
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList 配達リスト
   * @returns {Array<import("~/libs/commonTypes").DeliveryPackage>}
   */
  pruneOldDeliveredPackages(deliveryList) {
    const currentTimestamp = Date.now();
    return deliveryList.filter(
      (e) =>
        e.statusText !== "済" ||
        (Number.isInteger(e.deliveredTimestamp) && // 配完時間が設定されていない古いデータは削除
          currentTimestamp - e.deliveredTimestamp < 1000 * 60 * 60 * 24), // 配完後24時間未満
    );
  },

  /**
   * 配達リスト内に配達指定日時または再配達の希望日時が設定された荷物が含まれているかどうか
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} deliveryList
   * @returns {boolean}
   */
  existsPackageDesiredDateAndTime(deliveryList) {
    for (let i = 0; i < deliveryList.length; i++) {
      if (
        (!deliveryList[i].numberOfDeliveryAttempts &&
          (deliveryList[i].desiredDate || deliveryList[i].desiredTime)) ||
        (deliveryList[i].numberOfDeliveryAttempts &&
          (deliveryList[i].redeliveryContext?.adjustedRedeliveryDatetime ||
            deliveryList[i].specifiedPickupDatetime))
      ) {
        return true;
      }
    }
    return false;
  },
};

export default Object.freeze(deliveryListUtils);

/**
 * 持ち出し日時を比較する。
 * @param {import("~/libs/commonTypes").DeliveryPackage} lhs
 * @param {import("~/libs/commonTypes").DeliveryPackage} rhs
 * @returns {number} compareFnの比較結果
 */
function compareOutForDeliveryAt(lhs, rhs) {
  if (lhs.outForDeliveryAt === rhs.outForDeliveryAt) {
    return 0;
  } else if (lhs.outForDeliveryAt < rhs.outForDeliveryAt) {
    return -1;
  } else {
    return 1;
  }
}
