<script>
  import Checkbox from "@smui/checkbox";
  import Chip, { Set, Text, TrailingIcon } from "@smui/chips";
  import FormField from "@smui/form-field";
  import { getContext, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import sceneImageOutForDelivery from "~/assets/images/sceneOutForDelivery.png";
  import sceneImagePickupAndSort from "~/assets/images/scenePickupAndSort.png";
  import sceneImageReturnToEc from "~/assets/images/sceneReturnToEc.png";
  import sceneImageTakebackPackageToDepot from "~/assets/images/sceneTakebackPackageToDepot.png";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpOutForDelivery from "~/components/help/HelpOutForDelivery.svelte";
  import { initAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    NotificationCategory,
    QrHomeTypes,
    STATUS_HELD_IN_DEPOT,
    STATUS_RETURNED,
    STATUS_RETURNING,
    TO_HELD_IN_DEPOT,
    TO_IN_TRANSIT,
    TO_OUT_FOR_DELIVERY,
    TO_RETURN_TO_EC,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import geolocator from "~/libs/geolocator";
  import logger from "~/libs/logger";
  import { calcDistance, formatDistanceInMeters } from "~/libs/mapUtils";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { currentPositionStore, updateCenter } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/constants").QrHomeTypes} */
  export let qrHomeType;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let selectCenter = "";
  let selectWork;

  /** @type {Array<number>} */
  let returnWorkList = [STATUS_RETURNING, STATUS_RETURNED];

  /** @type {import("~/libs/constants").STATUS_RETURNING | import("~/libs/constants").STATUS_RETURNED}*/
  let selectReturnWork;

  /**
   * @typedef {{
   *   prefecture: string,
   *   centers: Array<{
   *     id: number,
   *     name: string,
   *     locationShortName: string,
   *     latitude: number,
   *     longitude: number,
   *     distance: string,
   *     isNeary: boolean
   *   }>
   * }} ExtraDepotLocation
   */

  /** @type {Array<ExtraDepotLocation>} */
  let locationList = [];

  /** 現在位置から最も近い配送センターのoption要素のvalue @type {string} */
  let nearestCenter;
  let geolocationIsAvailable = false;
  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;
  /** @type {import("svelte").ComponentType<HelpOutForDelivery>} */
  let helpContents;

  /** @type {import("svelte/store").Unsubscriber} */
  let currentPositionStoreUnsubscriber;

  /** センターIDをキーとしたセンター情報のMap @type {Map<number, import("~/libs/commonTypes").DepotLocation>} */
  let centersMap;

  /** @type {Array<object>} 輸送中荷物の情報 */
  let inTransitDeliveryList = [];

  /** @type {{
   *   transportDestinationId: number,
   *   numberOfBasketCar: number,
   * }} 荷下ろし対象の情報 */
  let unloadingTargetInfo = {
    transportDestinationId: null,
    numberOfBasketCar: 0,
  };

  /** @type {ConfirmDialog} 荷下ろし登録確認ダイアログ */
  let unloadingConfirmDialog;

  /** @type {boolean} 荷下ろしモード */
  let unloadingMode = false;

  /** 最寄りの配送センターのみを表示するか否か */
  let showsNearbyDepots = true;

  // ページの初期化処理（同期）
  (() => {
    // ページの表示タイプに応じて作業種別を特定
    switch (qrHomeType) {
      case QrHomeTypes.PICKUP_AND_SORT:
        selectWork = TO_IN_TRANSIT;
        break;
      case QrHomeTypes.OUT_FOR_DELIVERY:
        selectWork = TO_OUT_FOR_DELIVERY;
        break;
      case QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT:
        selectWork = TO_HELD_IN_DEPOT;
        break;
      case QrHomeTypes.RETURN_TO_EC:
        selectWork = TO_RETURN_TO_EC;
        break;
      default:
        throw new TypeError(`Illegal QrHomeTypes: ${qrHomeType}`);
    }

    // 持ち出しの場合かつ昨日以前に持出した荷物がある場合に警告を表示
    if (selectWork == TO_OUT_FOR_DELIVERY && hasLuggageBeforeYesterday()) {
      toast.error(
        "昨日以前に持ち出し登録をした荷物が配達リストに残っています。" +
          "配達を完了するか、持ち戻り登録を行ってください。",
        { initial: 0, popsWhenPageMoved: true },
      );
    }
  })();

  // ページの初期化処理（非同期）
  (async () => {
    // 作業場所（配送センター）の取得
    locationList = /** @type {Array<ExtraDepotLocation>} */ (
      await depotLocations.get()
    );

    // 現在地の取得
    geolocator.requestCurrentPosition(false);
    currentPositionStoreUnsubscriber = currentPositionStore.subscribe(
      (position) => {
        if (position) {
          setCoords(position);
        }
      },
    );

    // 荷受けの場合かつ輸送中荷物がある場合に配送センターリストを取得
    if (userContext.inTransitDeliveryList?.length) {
      centersMap = await depotLocations.getCentersMap();
      inTransitDeliveryList = userContext.inTransitDeliveryList;
      // 全ての荷下ろし中チェックを解除
      for (let i = 0; i < inTransitDeliveryList.length; i++) {
        for (
          let j = 0;
          j < inTransitDeliveryList[i].deliveryInfoList.length;
          j++
        ) {
          for (
            let k = 0;
            k <
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
            k++
          ) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c =
              false;
          }
        }
      }
    }
  })();

  // 60秒ごとに現在地を取得
  onMount(() => {
    const interval = setInterval(() => {
      geolocator.requestCurrentPosition(false);
    }, 60 * 1000);
    return () => clearInterval(interval);
  });

  onDestroy(() => {
    currentPositionStoreUnsubscriber?.();
  });

  /**
   * 配達リストに、昨日以前に持出した荷物が含まれている場合にtrueを返す
   * @returns {boolean}
   */
  function hasLuggageBeforeYesterday() {
    const numberOfPackagesGroupByStatus =
      userContext.getNumberOfPackagesGroupByStatus();
    if (
      numberOfPackagesGroupByStatus.未 > 0 ||
      numberOfPackagesGroupByStatus.不 > 0
    ) {
      const today = new Date().setHours(0, 0, 0, 0);
      const deliveryList = userContext.deliveryList;
      for (const delivery of deliveryList) {
        if (delivery.statusText != "済") {
          let outForDeliveryAt = new Date(delivery.outForDeliveryAt).getTime();
          if (outForDeliveryAt < today) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * @param {GeolocationPosition} position
   */
  function setCoords(position) {
    const sortList = [];

    // 現在位置から配送センターへの距離を算出
    for (let i = 0; i < locationList.length; i++) {
      let centers = locationList[i].centers;
      for (let j = 0; j < centers.length; j++) {
        const centerDistance = Math.round(
          calcDistance(
            position.coords.latitude,
            position.coords.longitude,
            centers[j].latitude,
            centers[j].longitude,
          ),
        );
        centers[j].distance = formatDistanceInMeters(centerDistance, false);
        let item = {
          locationIndex: i,
          centersIndex: j,
          distance: centerDistance,
        };
        sortList.push(item);
      }
    }

    // 現在位置からの距離順にソート
    let result = sortList.sort(function (a, b) {
      return a.distance < b.distance ? -1 : 1;
    });

    // 以前算出した「最寄り」がある場合はクリア
    if (nearestCenter) {
      nearestCenter = undefined;
      locationList.forEach((location) => {
        location.centers.forEach((center) => {
          center.isNeary = false;
        });
      });
    }

    // 最寄りセンターの判定：近い順に最大３個に対して"近い"判定にする
    for (let i = 0; i < Math.min(3, result.length); i++) {
      if (result[i].distance >= 1000) {
        // 現在位置から1000m以上離れている場合は該当件数に関わらず"近い"判定にはしない
        break;
      }
      if (!i) {
        // 最も近いセンターの場合（インデックス0番）
        nearestCenter =
          locationList[result[i].locationIndex].centers[result[i].centersIndex]
            .id +
          "/" +
          locationList[result[i].locationIndex].centers[result[i].centersIndex]
            .name;
      } else if (result[i].distance - result[0].distance >= 500) {
        // 最も近いセンターより500m以上離れている場合は該当件数に関わらず"近い"判定にはしない
        break;
      }

      locationList[result[i].locationIndex].centers[
        result[i].centersIndex
      ].isNeary = true;
    }

    locationList = locationList; // 距離・最寄りの配送センターをプルダウンに反映
    if (!selectCenter && nearestCenter) {
      /* センターが未選択かつ最寄りのセンターがある場合 */
      selectCenter = nearestCenter;
    } else if (nearestCenter && showsNearbyDepots) {
      /* (センターが選択済かつ)最寄りのセンターがある場合かつ最寄りのセンターのみ表示する場合 */
      if (
        !locationList
          .flatMap((e) => e.centers)
          .find((e) => e.id + "/" + e.name === selectCenter)?.isNeary
      ) {
        // 選択中のセンターが最寄りに該当しない場合は選択を解除
        selectCenter = "";
      }
    }
    geolocationIsAvailable = true;
  }

  function goToQrScan() {
    initAudioContext();

    if (selectReturnWork === STATUS_RETURNED) {
      updateCenter.set(null);
    } else {
      updateCenter.set(selectCenter);
    }

    switch (qrHomeType) {
      case QrHomeTypes.PICKUP_AND_SORT:
        pageRouter.moveToPickupAndSortWithQrCodeScan();
        break;
      case QrHomeTypes.OUT_FOR_DELIVERY:
        if (!appContext.firstOutForDeliveryStarted) {
          helpContents = HelpOutForDelivery;
          helpBase = HelpBase;
          appContext.firstOutForDeliveryStarted = true;
        } else {
          pageRouter.moveToOutForDeliveryWithQrCodeScan();
        }
        break;
      case QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT:
        pageRouter.moveToTakebackPackageToDepotWithQrCodeScan();
        break;
      case QrHomeTypes.RETURN_TO_EC:
        pageRouter.moveToReturnToEcWithQrCodeScan(selectReturnWork);
        break;
      default:
        throw new TypeError(`Illegal QrHomeTypes: ${qrHomeType}`);
    }

    appContext.store();
    userContext.store();
  }

  function clickConfirm() {
    helpBase = null;
    helpContents = null;
    pageRouter.moveToOutForDeliveryWithQrCodeScan(); // ヘルプ側でAudioContextを初期化
  }

  /**
   * 輸送中荷物の総個数を返す。
   * @param {object} inTransitDeliveryList
   * @returns {number}
   */
  function getNumberOfInTransitPackages(inTransitDeliveryList) {
    let count = 0;
    for (const inTransitDeliveryInfo of inTransitDeliveryList) {
      for (const deliveryInfo of inTransitDeliveryInfo.deliveryInfoList) {
        for (const basketCar of deliveryInfo.basketCarList) {
          count += basketCar.v.length;
        }
      }
    }
    return count;
  }

  /**
   * 指定された輸送元センターから指定された輸送先センターへの輸送中荷物の総個数を返す。
   * @param {object} inTransitDeliveryList
   * @param {number} transportSourceIndex
   * @param {number} transportDestinationIndex
   * @returns {number}
   */
  function getNumberOfInTransitPackagesByIndex(
    inTransitDeliveryList,
    transportSourceIndex,
    transportDestinationIndex,
  ) {
    let count = 0;
    for (const basketCar of inTransitDeliveryList[transportSourceIndex]
      .deliveryInfoList[transportDestinationIndex].basketCarList) {
      count += basketCar.v.length;
    }
    return count;
  }

  /**
   * 指定された輸送先センターへの輸送中カゴ車の総数を返す。
   * @param {number} transportDestinationId
   * @returns {number}
   */
  function getNumberOfInTransitBasketCars(transportDestinationId) {
    let count = 0;
    for (const inTransitDeliveryInfo of inTransitDeliveryList) {
      for (const deliveryInfo of inTransitDeliveryInfo.deliveryInfoList) {
        if (deliveryInfo.transportDestinationId === transportDestinationId) {
          count += deliveryInfo.basketCarList.length;
        }
      }
    }
    return count;
  }

  /**
   * 荷下ろし対象としてチェック済みのカゴ車と荷物の数を返す。
   * @returns {{
   *   packagesCount: number,
   *   basketCarsCount: number,
   * }}
   */
  function getNumberOfUnloadingPackages() {
    let packagesCount = 0;
    let basketCarsCount = 0;
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            basketCarsCount++;
            packagesCount +=
              inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].v
                .length;
          }
        }
      }
    }
    return { packagesCount, basketCarsCount };
  }

  /**
   * 荷下ろし登録を行い、Contextの輸送中荷物情報を更新する
   */
  async function updateStatuses() {
    try {
      const data = await execStatusUpdateApi();
      if (data && data.updateFailed) {
        const updateFailedNumbers = data.updateFailed;
        const outputTrackingNumbers = updateFailedNumbers
          .map((t) => formatTrackingNumber(t))
          .join(", ");
        toast.error(
          $_("errors.unableUnloading", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.ERROR,
          $_("errors.unableUnloading", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
      }
      // Contextの輸送中荷物情報を更新
      updateInTransitDeliveryList();
      toast.info($_("message.updateCompleteUnloading"));
    } catch (e) {
      logger.error(
        "[QrHome] 荷下ろし登録でエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          inTransitDeliveryList: inTransitDeliveryList,
        },
        e,
      );
      if (e instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * 荷下ろしした荷物を「保管中」ステータスに更新する。
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async function execStatusUpdateApi() {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[
              k
            ].v.forEach((trackingNumber) => {
              let event = {
                trackingNumber: trackingNumber,
                status: STATUS_HELD_IN_DEPOT,
                locationId:
                  inTransitDeliveryList[i].deliveryInfoList[j]
                    .transportDestinationId,
              };
              events.push(event);
            });
          }
        }
      }
    }
    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body);
  }

  /**
   * 荷下ろし登録後の輸送中荷物情報を更新する
   */
  function updateInTransitDeliveryList() {
    // タップされたカゴ車情報を削除
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.splice(
              k,
              1,
            );
            k--;
          }
        }
        if (
          inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length ===
          0
        ) {
          inTransitDeliveryList[i].deliveryInfoList.splice(j, 1);
          j--;
        }
      }
      if (inTransitDeliveryList[i].deliveryInfoList.length === 0) {
        inTransitDeliveryList.splice(i, 1);
        i--;
      }
    }

    // 輸送中荷物がなくなった場合、Contextの輸送中荷物情報を削除
    if (inTransitDeliveryList.length === 0) {
      inTransitDeliveryList = undefined;
    }

    inTransitDeliveryList = inTransitDeliveryList;
    userContext.inTransitDeliveryList = inTransitDeliveryList;
    userContext.store();
    unloadingMode = false;
  }

  /**
   * 荷下ろし対象が選択されていない場合に荷下ろしモードを解除する。
   */
  function checkUnloadingModeRelease() {
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            return;
          }
        }
      }
    }
    unloadingMode = false;
    unloadingTargetInfo = {
      transportDestinationId: null,
      numberOfBasketCar: 0,
    };
  }

  /**
   * 輸送元センター単位のリストに、荷下ろし対象の輸送先センターが含まれているかを返す。
   * @param {object} deliveryInfoList
   * @returns {boolean}
   */
  function includeTargetDestination(deliveryInfoList) {
    return deliveryInfoList.some(
      (deliveryInfo) =>
        deliveryInfo.transportDestinationId ===
        unloadingTargetInfo.transportDestinationId,
    );
  }
</script>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="center">{selectWork}</svelte:fragment>
  </Header>

  <main in:fade>
    <p class="img">
      <img
        src={{
          [QrHomeTypes.PICKUP_AND_SORT]: sceneImagePickupAndSort,
          [QrHomeTypes.OUT_FOR_DELIVERY]: sceneImageOutForDelivery,
          [QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT]:
            sceneImageTakebackPackageToDepot,
          [QrHomeTypes.RETURN_TO_EC]: sceneImageReturnToEc,
        }[qrHomeType]}
        alt="業務イメージ"
      />
    </p>

    <div class="inputAreaWrapper">
      <div class="inputArea">
        {#if selectWork === TO_RETURN_TO_EC}
          <div class="inputGroup">
            <p class="groupName">作業内容</p>
            <div class="groupContent inputReturnWorkArea">
              <label class="inputReturnWorkLabel">
                <select
                  name="inputReturnWork"
                  class="selectInput"
                  id="inputReturnWork"
                  bind:value={selectReturnWork}
                >
                  <option value="" selected disabled>選択してください</option>
                  {#each returnWorkList as status}
                    <option value={status}
                      >{$_(`classes.returnWork.${status}`)}</option
                    >
                  {/each}
                </select>
              </label>
            </div>
          </div>
        {/if}
        {#if selectWork != TO_RETURN_TO_EC || selectReturnWork === STATUS_RETURNING}
          <div class="inputGroup">
            <p class="groupName">作業場所 (配送センター)</p>
            <div class="groupContent inputCenterArea">
              {#if locationList != null}
                <label class="inputCenterLabel">
                  <select
                    name="inputCenter"
                    class="selectInput"
                    id="inputCenter"
                    bind:value={selectCenter}
                  >
                    <option value="" disabled selected={!selectCenter}
                      >選択してください</option
                    >
                    {#each locationList as { prefecture, centers }}
                      {#if showsNearbyDepots && nearestCenter}
                        {#each centers as { id, name, distance, isNeary }}
                          {#if isNeary}
                            <option
                              value={id + "/" + name}
                              selected={id + "/" + name === selectCenter}
                              >{name}{#if distance}{" (" +
                                  distance +
                                  ")"}{/if}</option
                            >
                          {/if}
                        {/each}
                      {:else}
                        <optgroup label={prefecture}>
                          {#each centers as { id, name, distance }}
                            <option
                              value={id + "/" + name}
                              selected={id + "/" + name === selectCenter}
                              >{name}{#if distance}{" (" +
                                  distance +
                                  ")"}{/if}</option
                            >
                          {/each}
                        </optgroup>
                      {/if}
                    {/each}
                  </select>
                </label>
                {#if nearestCenter}
                  <FormField>
                    <Checkbox
                      bind:checked={showsNearbyDepots}
                      on:change={(event) => {
                        if (
                          /** @type {HTMLInputElement} */ (event.target).checked
                        ) {
                          if (
                            !locationList
                              .flatMap((e) => e.centers)
                              .find((e) => e.id + "/" + e.name === selectCenter)
                              ?.isNeary
                          ) {
                            // 最寄りの配送センターに絞り込んだ際に元々選択していた配送センターが非表示になる場合
                            selectCenter = "";
                          }
                        }
                      }}
                    />
                    <span slot="label">最寄りの配送センターのみ表示</span>
                  </FormField>
                {/if}
                {#if geolocationIsAvailable && (!nearestCenter || (selectCenter && selectCenter != nearestCenter))}
                  <div style="display: flex;">
                    <p class="notes" style="width: 15px;">※</p>
                    <p class="notes">
                      {#if !nearestCenter}
                        最寄りの配送センターがありません
                      {:else}
                        最も近い配送センター以外が選択されています
                      {/if}
                    </p>
                  </div>
                {/if}
              {:else}
                <select
                  name="inputCenter"
                  class="selectInput"
                  id="inputCenter"
                  disabled
                >
                  <option selected>取得失敗</option>
                </select>
              {/if}
            </div>
          </div>
        {/if}
      </div>
    </div>

    {#if selectWork === TO_HELD_IN_DEPOT}
      <div class="captionLabel">
        <span class="material-icons"> info_outline </span>
        <p>
          読み取り画面にて、荷物ごとに持ち戻り対象・返品対象を選択することができます。
        </p>
      </div>
    {/if}

    {#if userContext.hasContractDriverRole() && selectWork === TO_IN_TRANSIT && userContext.inTransitDeliveryList?.length && centersMap}
      <!-- IF 基幹輸送ドライバーによる荷受け業務時、かつ荷受けした荷物がある場合 -->
      <div class="scanCountInfoArea">
        <div class="totalScanCountArea">
          <p class="totalScanCount">
            {#if !unloadingMode}
              輸送中荷物の総数 {getNumberOfInTransitPackages(
                inTransitDeliveryList,
              )}個
            {:else}
              {centersMap?.get(unloadingTargetInfo.transportDestinationId).name}
              行のカゴ車 {unloadingTargetInfo.numberOfBasketCar}台
            {/if}
          </p>
        </div>

        <div class="tranceportSourceUnitArea">
          {#each inTransitDeliveryList as transportSourceInfo, i}
            {#if !unloadingMode || (unloadingMode && includeTargetDestination(transportSourceInfo.deliveryInfoList))}
              <div class="tranceportSourceUnit">
                <p class="tranceportSourceName">
                  <strong
                    >{centersMap?.get(transportSourceInfo.transportSourceId)
                      .name}</strong
                  > 発
                </p>
                <div class="tranceportDestinationUnitArea">
                  {#each transportSourceInfo.deliveryInfoList as transportDestinationInfo, j}
                    {#if !unloadingMode || (unloadingMode && transportDestinationInfo.transportDestinationId === unloadingTargetInfo.transportDestinationId)}
                      <p class="tranceportDestinationName">
                        <strong
                          >{centersMap?.get(
                            transportDestinationInfo.transportDestinationId,
                          ).name}</strong
                        >
                        行（カゴ車{transportDestinationInfo.basketCarList
                          .length}台／荷物{getNumberOfInTransitPackagesByIndex(
                          inTransitDeliveryList,
                          i,
                          j,
                        )}個）
                      </p>
                      <Set
                        chips={transportDestinationInfo.basketCarList}
                        let:chip
                        nonInteractive
                      >
                        <Chip
                          {chip}
                          shouldRemoveOnTrailingIconClick={false}
                          style={chip.c
                            ? "background-color: #018786; color: white;"
                            : ""}
                          on:click={(event) => {
                            event.stopPropagation();

                            chip.c = !chip.c;
                            inTransitDeliveryList = inTransitDeliveryList;
                            if (chip.c && !unloadingMode) {
                              // 荷下ろしモードに切り替え
                              unloadingTargetInfo.transportDestinationId =
                                transportDestinationInfo.transportDestinationId;
                              unloadingTargetInfo.numberOfBasketCar =
                                getNumberOfInTransitBasketCars(
                                  transportDestinationInfo.transportDestinationId,
                                );
                              unloadingMode = true;
                            } else if (!chip.c && unloadingMode) {
                              // 荷下ろしモードを解除判定
                              checkUnloadingModeRelease();
                            }
                          }}
                        >
                          <Text>{chip.v.length}個</Text>
                          <TrailingIcon>
                            <span
                              class="material-icons md-18"
                              class:selected={chip.c}
                            >
                              check_circle
                            </span>
                          </TrailingIcon>
                        </Chip>
                      </Set>
                    {/if}
                  {/each}
                </div>
              </div>
            {/if}
          {/each}
        </div>

        <div class="scanCountInfoNoteArea">
          <p class="scanCountInfoNote">
            {#if !unloadingMode}
              輸送先で荷物を降ろす際に、対象のカゴ車の
              <span class="material-icons md-18 inlineIcon">
                check_circle
              </span>
              マークをタップしてください。
            {:else}
              全てのカゴ車の荷下ろしを終えたら、『荷下ろし済みとして登録する』ボタンをタップしてください。
            {/if}
          </p>
        </div>
      </div>
    {/if}

    <button
      class="qr_btn"
      class:disabled_btn={(!unloadingMode &&
        !selectCenter &&
        (qrHomeType !== QrHomeTypes.RETURN_TO_EC ||
          selectReturnWork !== STATUS_RETURNED)) ||
        !selectWork ||
        (qrHomeType === QrHomeTypes.RETURN_TO_EC &&
          !Number.isInteger(selectReturnWork))}
      on:click={() => {
        if (!unloadingMode) {
          goToQrScan();
        } else {
          unloadingConfirmDialog.openDialog();
        }
      }}
      disabled={(!unloadingMode &&
        !selectCenter &&
        (qrHomeType !== QrHomeTypes.RETURN_TO_EC ||
          selectReturnWork !== STATUS_RETURNED)) ||
        !selectWork ||
        (qrHomeType === QrHomeTypes.RETURN_TO_EC &&
          !Number.isInteger(selectReturnWork))}
    >
      {#if !unloadingMode}
        QRコードスキャンを開始する
      {:else}
        荷下ろし済みとして登録する
      {/if}
    </button>
  </main>

  <Footer />
</div>

<div class="subContentsWrapper">
  <!-- ヘルプ表示 -->
  <svelte:component this={helpBase} {helpContents} {clickConfirm} />

  <!-- 荷下ろし登録確認ダイアログ -->
  <ConfirmDialog
    bind:this={unloadingConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CANCEL_CLOSE}
    onDialogClosedHandler={async (event) => {
      if (event.detail.action === "ok") {
        await updateStatuses();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content">
      {@const unloadingCount = getNumberOfUnloadingPackages()}
      カゴ車{unloadingCount.basketCarsCount}台（荷物{unloadingCount.packagesCount}個）を
      {centersMap.get(unloadingTargetInfo.transportDestinationId)
        .name}に荷下ろし済みとして登録します。
      {#if unloadingCount.basketCarsCount < unloadingTargetInfo.numberOfBasketCar}
        <div class="unloadingOmissionNote">
          <p>
            {centersMap.get(unloadingTargetInfo.transportDestinationId)
              .name}行で未チェックのカゴ車が{unloadingTargetInfo.numberOfBasketCar -
              unloadingCount.basketCarsCount}台あります。登録を続行しますか？
          </p>
        </div>
      {/if}
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  /* ------------
  コンテンツ部分
 --------------- */
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p.img {
    text-align: center;

    img {
      width: calc(100% - 80px);
    }
  }

  .inputAreaWrapper {
    width: calc(100% - 30px);
    margin: 20px 15px 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .inputArea {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 15px;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;

    .groupName {
      border-bottom: solid 1px #b2b2b2;
      padding-bottom: 4px;
      font-size: 14px;
    }

    .groupContent {
      margin: 10px 0 0 8px;
    }
  }

  .inputCenterArea {
    .inputCenterLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        right: 15px;
        width: 10px;
        height: 7px;
        background-color: #535353;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        content: "";
        pointer-events: none;
      }

      select {
        appearance: none;
        width: 100%;
        height: 2.8em;
        padding: 0.4em 30px 0.4em 0.4em;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
        overflow-x: hidden;
      }
    }

    .selectInput {
      height: 40px;
    }

    .notes {
      width: 100%;
      text-align: left;
      line-height: 15px;
      margin-top: 3px;
      font-size: 12px;
      color: red;
    }
  }

  .inputReturnWorkArea {
    .inputReturnWorkLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        right: 15px;
        width: 10px;
        height: 7px;
        background-color: #535353;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        content: "";
        pointer-events: none;
      }

      select {
        appearance: none;
        width: 100%;
        height: 2.8em;
        padding: 0.4em 30px 0.4em 0.4em;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }

      .selectInput {
        height: 40px;
      }
    }
  }

  .captionLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    margin: 20px 15px 0;
    color: #018786;
    background-color: #01878616;
    border-radius: 8px;
    line-height: 1.3;

    .material-icons {
      font-size: 24px;
    }
  }

  .scanCountInfoArea {
    width: calc(100% - 40px);
    max-height: 25vh;
    margin-top: 12px;

    .totalScanCountArea {
      display: flex;
      width: 100%;
      // border-bottom: solid 1px #444444;
      padding-bottom: 6px;

      .totalScanCount {
        margin-left: 5px;
        font-size: 15px;
        font-weight: bold;
      }
    }

    .tranceportSourceUnitArea {
      max-height: calc(100% - 40px);
      padding: 8px 0 0 5px;
      overflow-y: auto;
      background-color: white;
      border: solid 0.5px #c7c7c7;

      .tranceportSourceUnit {
        margin-bottom: 12px;

        .tranceportSourceName {
          margin-left: 5px;
          font-size: 14px;
        }

        .tranceportDestinationUnitArea {
          margin: 5px 0 0 5px;
          padding: 3px 0 0 2px;
          border-left: solid 5px #c9c7c7;

          .tranceportDestinationName {
            margin-left: 5px;
            margin-bottom: -3px;
            font-size: 14px;
          }

          .selected {
            color: white;
          }
        }
      }
    }

    .scanCountInfoNoteArea {
      margin: 5px 0 0 10px;

      .scanCountInfoNote {
        font-size: 14px;
        line-height: 1.2;

        .inlineIcon {
          vertical-align: middle;
        }
      }
    }
  }

  .qr_btn {
    margin-top: 40px;
    padding: 14px 10px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    font-size: 17px;
    width: 80%;
    max-width: 350px;

    &.disabled_btn {
      background-color: #cccccc;
    }
  }

  .unloadingOmissionNote {
    margin-top: 5px;
    padding: 4px 8px;
    background-color: #ffe8e8;
    border-radius: 4px;
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
  }
</style>
