<script>
  import Button, { Label } from "@smui/button";
  import LinearProgress from "@smui/linear-progress";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi, {
    ErrorResponseException,
    OfflineException,
  } from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    BackendApiName,
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    DISABLE_ROLES,
    NotificationCategory,
  } from "~/libs/constants";
  import { db } from "~/libs/db";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { needToReload } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpend = false;

  /** @type {Array} リクエストデータのキュー*/
  let requestsQueue;

  /** @type {boolean} 送信開始の承認済みフラグ */
  let approved = false;

  /** @type {boolean} オンラインモードへの切替え失敗フラグ */
  let switchingFailure = false;

  /** @type {boolean} ログイン認証の失敗フラグ */
  let loginFailure = false;

  /** @type {boolean} スタックデータなしフラグ */
  let noStackData = false;

  /** @type {string} オンラインモードへの切替え失敗時の表示メッセージ */
  let switchingErrorMessage;

  /** @type {number} 進捗バーの数値 */
  let progress = 0.1;

  /** @type {string} ログイン失敗による削除対象のリクエストID */
  let deleteTargetId;

  /** @type {boolean} フェードイン／アウト用フラグ */
  let close = true;

  /** @type {Array<string>} ステータス更新APIでupdateFailedが応答された送り状番号 */
  let updateFailedList = [];

  /**
   * 送信待ち送り状番号リスト
   */
  let stackShippingNumberList = [];

  /**
   * オンラインモードへの切替えに失敗した例外
   */
  class SwitchingFailureException extends Error {
    constructor(message) {
      super(message);
      this.name = "SwitchingFailureExceptionByOffline";
    }
  }

  /**
   * ログイン認証に失敗した例外
   */
  class LoginFailureException extends Error {
    constructor(message) {
      super(message);
      this.name = "LoginFailureException";
    }
  }

  /**
   * ダイアログを開く。
   */
  export async function openDialog() {
    // 更新待ちの送り状番号をリスト化
    stackShippingNumberList = [];
    const stackList = await db.requestsQueue
      .filter((r) => r.backendApiName === BackendApiName.UPDATE_SHIPMENT_STATUS)
      .toArray();
    for (let i = 0; i < stackList.length; i++) {
      if (stackList[i].data[0][0] == "status-update-event") {
        stackShippingNumberList.push(
          formatTrackingNumber(
            JSON.parse(
              await stackList[i].data[0][1].text().then((json) => json),
            ).events[0].trackingNumber,
          ),
        );
      } else {
        stackShippingNumberList.push(
          formatTrackingNumber(
            JSON.parse(
              await stackList[i].data[1][1].text().then((json) => json),
            ).events[0].trackingNumber,
          ),
        );
      }
    }

    //
    requestsQueue = await db.requestsQueue.toArray();
    if (!navigator.onLine) {
      switchingErrorMessage = $_("errors.offline");
      approved = false;
      switchingFailure = true;
      dialogOpend = true;
      close = false;
    } else if (requestsQueue.length) {
      dialogOpend = true;
      close = false;
    } else {
      noStackData = true;
      switchingErrorMessage = $_("message.noStackData");
      dialogOpend = true;
      close = false;
    }
  }

  function initFlag() {
    approved = false;
    switchingFailure = false;
    loginFailure = false;
  }

  async function cancel() {
    close = true;
    await setTimeout(() => {
      dialogOpend = false;
      initFlag();
    }, 500);
  }

  function ok() {
    approved = true;
    sendQueueData();
  }

  /** オンラインモードへの切替え */
  async function switching() {
    appContext.offlineMode = false;
    appContext.store();
    close = true;
    await setTimeout(() => {
      dialogOpend = false;
      initFlag();
      pageRouter.moveToList();
      if (updateFailedList.length) {
        switchingErrorMessage = $_("errors.isCannotBeDeliveredPackage", {
          values: { trackingNumber: updateFailedList.join("、") },
        });
        toast.error(switchingErrorMessage, { popsWhenPageMoved: true });
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.ERROR,
          switchingErrorMessage,
        );
      }
    }, 500);
  }

  /** オンラインモードへ切り替えたうえで、ログイン画面を表示 */
  function goToLogin() {
    appContext.failedToSwitchOnline = true;
    appContext.store();
    // 不要なログインAPIリクエストをキューから削除
    deleteQueueData(deleteTargetId);
    // ログアウト処理
    pageRouter.moveToLogin();
  }

  async function sendQueueData() {
    const incremental = (1 - 0.1) / requestsQueue.length;
    const shippingList = userContext.deliveryList ?? [];
    let updateResponse;
    try {
      for (let i = 0; i < requestsQueue.length; i++) {
        setTimeout(null, 500);
        switch (requestsQueue[i].backendApiName) {
          case BackendApiName.LOGIN:
            await execLoginApi(requestsQueue[i]);
            await deleteQueueData(requestsQueue[i].id);
            progress += incremental;
            break;
          case BackendApiName.UPDATE_SHIPMENT_STATUS:
            updateResponse = await execUpdateShipmentStatusApi(
              requestsQueue[i],
            );
            await updateLocalStorage(updateResponse, shippingList);
            await deleteQueueData(requestsQueue[i].id);
            progress += incremental;
            break;
          default:
            console.log("default");
            break;
        }
      }
      if ($needToReload) {
        userContext.deliveryList = shippingList;
        userContext.store();
      }
      setTimeout(switching, 500);
    } catch (error) {
      console.error(error);
      switchingErrorMessage = error.message;
      approved = false;
      // オフラインエラー・サーバエラーだったらその旨表示し、
      // オフラインモードのまま終了
      if (error instanceof SwitchingFailureException) {
        switchingFailure = true;
      }
      // ログイン認証に失敗したエラーだったらその旨表示し、
      // 再ログインさせる
      else if (error instanceof LoginFailureException) {
        loginFailure = true;
      }
    }
  }

  async function execLoginApi(data) {
    try {
      const responseBody = await backendApi.login(data.data);
      if (DISABLE_ROLES.includes(responseBody.roles[0])) {
        throw new HandledError($_("errors.disableRoles"));
      } else {
        const currentTime = Date.now();
        userContext.loginUser = {
          username: responseBody.username,
          roles: responseBody.roles,
          accessToken: responseBody.accessToken,
          refreshToken: responseBody.refreshToken,
          expiresIn: responseBody.expiresIn,
          refreshExpires:
            responseBody.refreshExpiresIn > 0
              ? currentTime + responseBody.refreshExpiresIn * 1000
              : undefined,
          loginTime: currentTime,
          displayName: responseBody.displayName,
          companyId: responseBody.companyId,
          companyName: responseBody.companyName,
          emailAddress: responseBody.emailAddress,
        };
        userContext.store();
      }
    } catch (error) {
      // オフライン状態の場合
      if (error instanceof OfflineException) {
        // オフライン状態である旨メッセージ表示して終了
        throw new SwitchingFailureException($_("errors.offline"));
      }
      // 初期パスワード変更が必要な場合
      else if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "Require password reset."
      ) {
        // ログインのリクエストは削除対象として保持
        deleteTargetId = data.id;
        throw new LoginFailureException($_("errors.switchingFailureByLogin"));
      }
      // サーバーエラー応答を受信した場合
      else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status != 401 &&
        error.response.status != 400
      ) {
        // オンラインモードへの切替えに失敗した旨
        // メッセージを表示
        throw new SwitchingFailureException($_("errors.defaultMessage"));
      }
      // ID・パスワード誤りやアカウントロックの場合
      else {
        console.error(error);
        // ログインのリクエストは削除対象として保持
        deleteTargetId = data.id;
        throw new LoginFailureException($_("errors.switchingFailureByLogin"));
      }
    }
  }

  /**
   * @param {object} data // TODO: 型を定義
   * @returns {Promise<object>}
   */
  async function execUpdateShipmentStatusApi(data) {
    // ログインチェック
    if (userContext.loginUser?.accessToken == undefined) {
      throw new LoginFailureException($_("errors.switchingFailureByLogin"));
    }
    try {
      const newBody = new FormData();
      for (let [key, value] of data.data) {
        newBody.append(key, value);
      }
      return await backendApi.updateShipmentStatus(newBody);
    } catch (error) {
      // オフライン状態の場合
      if (error instanceof OfflineException) {
        // オフライン状態である旨メッセージ表示して終了
        throw new SwitchingFailureException($_("errors.offline"));
      }
      // アクセストークンの期限切れエラーの場合
      else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 401
      ) {
        console.error(error);
        // ログインしてから再度送信を促すメッセージを表示し、
        // OKならログイン画面に遷移
        throw new LoginFailureException($_("errors.unauthorized"));
      }
      // 権限エラー等の場合
      else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status === 403
      ) {
        console.error(error);
        // ログインしてから再度送信を促すメッセージを表示し、
        // OKならログイン画面に遷移
        throw new LoginFailureException($_("errors.forbidden"));
      }
      // その他サーバーエラー応答を受信した場合
      else {
        // オンラインモードへの切替えに失敗した旨
        // メッセージを表示
        throw new SwitchingFailureException($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * 配達リストに更新対象の荷物が含まれていた場合、表示用のステータスを更新する
   * @param {object} updateResponse // TODO: 型を定義
   * @param {Array<import("~/libs/commonTypes").DeliveryPackage>} shippingList
   */
  async function updateLocalStorage(updateResponse, shippingList) {
    if (shippingList.length) {
      // localStorageに配達リストが存在した場合
      if (updateResponse.updateFailed) {
        // 更新結果がupdateFailedだった場合
        const updateTarget = shippingList.find(
          (shipment) =>
            shipment.trackingNumber === updateResponse.updateFailed[0],
        );

        if (updateTarget) {
          // 配達リストに該当する荷物があったら更新フラグを立てる
          needToReload.set(true);
        } else {
          // 配達リストに該当する荷物がなかったら、更新失敗リストに追加
          updateFailedList.push(
            formatTrackingNumber(updateResponse.updateFailed[0]),
          );
        }
      } else {
        // 更新結果が成功だった場合
        const updateTarget = shippingList.find(
          (shipment) =>
            shipment.trackingNumber ===
            updateResponse.success[0].trackingNumber,
        );

        if (updateTarget) {
          // 配達リストに該当する荷物があったらステータスを更新
          updateTarget.statusText = "済";
          updateTarget.deliveredTimestamp = Date.now();
        }
      }
    } else {
      // localStorageに配達リストが存在しなかった場合
      if (updateResponse.updateFailed) {
        // 更新結果がupdateFailedだった場合、更新失敗リストに追加
        updateFailedList.push(
          formatTrackingNumber(updateResponse.updateFailed[0]),
        );
      }
    }
  }

  /** キューからリクエストデータを削除
   * @param {string} id
   */
  async function deleteQueueData(id) {
    db.requestsQueue.delete(id);
  }
</script>

{#if dialogOpend}
  <div class="filter" class:fadein={!close} class:fadeout={close}>
    <div class="paper">
      {#if !approved && !switchingFailure && !loginFailure && !noStackData}
        <p class="notice">
          オフラインモードの間に発生した更新データの送信を開始します。<br />
          よろしいですか？<br />
          ※更新データの量によっては多少時間を要する場合があります。
        </p>

        {#if stackShippingNumberList.length}
          <div class="listArea">
            <div class="listTitle">
              更新対象の荷物（{stackShippingNumberList.length}件）
            </div>
            <div class="list">
              <ul>
                {#each stackShippingNumberList as shippingNumber}
                  <li class="listItem">{shippingNumber}</li>
                {/each}
              </ul>
            </div>
          </div>
        {/if}

        <div class="buttonArea">
          <Label>
            <Button
              style="margin-right: 20px"
              color="secondary"
              variant="outlined"
              on:click={cancel}
            >
              キャンセル
            </Button>
          </Label>
          <Label>
            <Button color="secondary" variant="outlined" on:click={ok}>
              OK
            </Button>
          </Label>
        </div>
      {:else if approved}
        <p class="notice">
          更新データを送信中...<br />
          ※アプリを閉じないでください。
        </p>
        <div class="progressArea">
          <LinearProgress {progress} />
        </div>
      {:else if switchingFailure || loginFailure || noStackData}
        <p class="notice">
          <!-- ja.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
          {@html switchingErrorMessage}
        </p>
        {#if stackShippingNumberList.length}
          <div class="listArea">
            <div class="listTitle">
              更新対象の荷物（{stackShippingNumberList.length}件）
            </div>
            <div class="list">
              <ul>
                {#each stackShippingNumberList as shippingNumber}
                  <li class="listItem">{shippingNumber}</li>
                {/each}
              </ul>
            </div>
          </div>
        {/if}
        <div class="buttonArea">
          {#if switchingFailure}
            <Label>
              <Button color="secondary" variant="outlined" on:click={cancel}>
                OK
              </Button>
            </Label>
          {:else if loginFailure}
            <Label>
              <Button
                style="margin-right: 20px"
                color="secondary"
                variant="outlined"
                on:click={cancel}
              >
                キャンセル
              </Button>
            </Label>
            <Label>
              <Button color="secondary" variant="outlined" on:click={goToLogin}>
                ログイン画面へ
              </Button>
            </Label>
          {:else if noStackData}
            <Label>
              <Button
                style="margin-right: 20px"
                color="secondary"
                variant="outlined"
                on:click={cancel}
              >
                キャンセル
              </Button>
            </Label>
            <Label>
              <Button color="secondary" variant="outlined" on:click={switching}>
                OK
              </Button>
            </Label>
          {/if}
        </div>
      {/if}
    </div>
  </div>
{/if}

<style lang="scss">
  .filter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 510;
  }
  .fadein {
    animation-name: fadein;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .fadeout {
    animation-name: fadeout;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .paper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 90vw;
    max-width: 400px;
    min-width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .notice {
    margin: 20px 20px 0;
    line-height: 21px;
    color: black;
  }

  .listArea {
    margin: 15px 0 0;
    width: 70%;
  }

  .listTitle {
    display: columns;
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
    background-color: var(--mdc-theme-secondary);
    color: white;
    border-radius: 10px 10px 0 0;
  }

  .list {
    width: 100%;
    padding: 10px 0;
    max-height: 100px;
    overflow-y: auto;
    background-color: ghostwhite;
    border-radius: 0 0 10px 10px;
  }

  .listItem {
    padding: 5px 0;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
  }

  .buttonArea {
    width: 100%;
    margin: 20px;
    display: flex;
    justify-content: center;
  }

  .progressArea {
    margin: 20px 0 30px;
    width: 80%;
  }
</style>
