<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";

  import { ConfirmDialogTypes } from "~/libs/constants";

  /** @type {ConfirmDialogTypes} 確認ダイアログのタイプ */
  export let type;

  /** @type {boolean} ESCキーやblurでダイアログを閉じないようにするか否か */
  export let mandatory = false;

  /**
   * @callback onDialogClosedCallback
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  /**
   * @type {onDialogClosedCallback}
   */
  export let onDialogClosedHandler = () => {};

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpend = false;

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    dialogOpend = true;
  }
</script>

{#if dialogOpend}
  <div class="confirmDialog">
    <Dialog
      bind:open={dialogOpend}
      scrimClickAction={mandatory ? "" : "close"}
      escapeKeyAction={mandatory ? "" : "close"}
      on:SMUIDialog:closed={onDialogClosedHandler}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-content"
    >
      <Header>
        <Title id="confirm-dialog-title"><slot name="title" /></Title>
        {#if type !== ConfirmDialogTypes.OK}
          <IconButton
            action="close"
            ripple={false}
            class="material-icons"
            style="position: absolute; top: 5px; right: 5px;">close</IconButton
          >
        {/if}
      </Header>
      <Content id="confirm-dialog-content"><slot name="content" /></Content>
      {#if type !== ConfirmDialogTypes.CLOSE}
        <Actions>
          <Button action="ok" name="ok">
            <Label><slot name="okLabel">OK</slot></Label>
          </Button>
          {#if type === ConfirmDialogTypes.OK_CANCEL_CLOSE}
            <Button action="cancel">
              <Label><slot name="cancelLabel">キャンセル</slot></Label>
            </Button>
          {/if}
        </Actions>
      {/if}
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .confirmDialog {
    :global(.mdc-dialog__content) {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
</style>
